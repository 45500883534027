<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-4">
        <div
          class="col-12 col-md-12 m-0 p-0"
          style="margin-top: -5px !important;"
        >
          <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
        </div>
      </div>
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12">
            <v-btn
              class="float-right"
              outlined
              color="black"
              :to="
                currentRoles.includes('SCO$UDAF')
                  ? `/dashboard-udaf`
                  : `/proyectos/cns/estimacion-presupuesto/admin-dash`
              "
            >
              <v-icon>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <span class="card-label font-weight-bolder text-dark">
              Consolidado Estimación anual del presupuesto </span
            ><br />
            <v-skeleton-loader
              v-if="skeletonLoading"
              type="list-item-two-line"
            ></v-skeleton-loader>
            <div v-if="!skeletonLoading">
              <span class="text-muted mt-3 font-weight-bold font-size-md">
                Generación de consolidado general
              </span>
            </div>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="4">
            <v-btn
              color="light-blue-502"
              dark
              class="white--text mb-2 float-right"
              @click="modalNuevo"
              :disabled="skeletonLoading || accionesBloqueadas"
            >
              Generar consolidado
            </v-btn>
          </v-col> -->
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-022 pr-022">
        <v-row>
          <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
            <v-select
              v-model="datosConsolidado.periodosId"
              :items="periodos"
              :loading="ddPeriodosLoading"
              dense
              filled
              label="Año de estimación"
              item-text="periodo"
              item-value="id"
              :rules="[selectRequired('periodo / año')]"
              :no-data-text="
                periodos != null
                  ? 'Selecciona un periodo'
                  : 'No se han encontrado periodos'
              "
              menu-props="auto"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0"> <!--v-if="mostrarUnidadesEjecutoras"-->
            <v-select
              v-model="datosConsolidado.tipoFinanciamiento"
              :items="tipoProyectoItems"
              :loading="false"
              dense
              filled
              class="required"
              label="Financiamiento"
              item-text="text"
              item-value="id"
              :rules="[selectRequired('tipo de proyecto')]"
              :no-data-text="
                tipoProyectoItems.length > 0
                  ? 'Seleccione un tipo de proyecto'
                  : 'No se han encontrado tipos de proyecto'
              "
              menu-props="auto"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
            <v-btn
              color="light-blue-502"
              class="white--text mb-2 float-right mr-2"
              type="submit"
              :elevation="0"
              :disabled="btnRegistroLoading || !datosConsolidado.tipoFinanciamiento"
              :loading="btnRegistroLoading"
              :href="
                `https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ConsolidadoEstimacionAnualGeneralCNS/ConsolidadoEstimacionAnualGeneralCNS.csv?endpoint=EstimacionAnualCNS/Consolidado/General/Documento/${
                  datosConsolidado.tipoFinanciamiento == 3
                    ? 0
                    : datosConsolidado.tipoFinanciamiento
                }/${
                  datosConsolidado.periodosId
                }&j_password=reportes&j_username=reporte`
              "
              target="_blank"
              >
              <v-icon left>mdi-file-delimited</v-icon> Descargar csv
            </v-btn>

            <v-btn
              color="teal lighten-1"
              class="white--text mb-2 float-right mr-2"
              type="submit"
              :elevation="0"
              :disabled="btnRegistroLoading || !datosConsolidado.tipoFinanciamiento"
              :loading="btnRegistroLoading"
              :href="
                `https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ConsolidadoEstimacionAnualGeneralCNS/ConsolidadoEstimacionAnualGeneralCNS.xls?endpoint=EstimacionAnualCNS/Consolidado/General/Documento/${
                  datosConsolidado.tipoFinanciamiento == 3
                    ? 0
                    : datosConsolidado.tipoFinanciamiento
                }/${
                  datosConsolidado.periodosId
                }&j_password=reportes&j_username=reporte`
              "
              target="_blank"
            >
              <v-icon left>mdi-file-excel-box</v-icon> Descargar xls
            </v-btn>

            <v-btn
              color="red lighten-1"
              class="white--text mb-2 float-right mr-2"
              type="submit"
              :elevation="0"
              :disabled="btnRegistroLoading || !datosConsolidado.tipoFinanciamiento"
              :loading="btnRegistroLoading"
              :href="
                `https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ConsolidadoEstimacionAnualGeneralCNS/ConsolidadoEstimacionAnualGeneralCNS.pdf?endpoint=EstimacionAnualCNS/Consolidado/General/Documento/${
                  datosConsolidado.tipoFinanciamiento == 3
                    ? 0
                    : datosConsolidado.tipoFinanciamiento
                }/${
                  datosConsolidado.periodosId
                }&j_password=reportes&j_username=reporte`
              "
              target="_blank"
            >
              <v-icon left>mdi-file-pdf-box</v-icon> Descargar pdf
            </v-btn>                    
          </v-col>
        </v-row>
        <v-data-table
          class="elevation-0 d-none"
          :headers="headers"
          :items="consolidados"
          :search="filtro"
          :loading="tableLoading"
          no-data-text="No se encontraron registros"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-form ref="form">
                <!-- <v-row>
                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      :disabled="false"
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="datosConsolidado.nombreDirectorAdminFinanciera"
                      label="Nombre del director de la administración financiera"
                      :rules="[required('director de la administración financiera')]"
                      maxlength="100"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      :disabled="false"
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="datosConsolidado.nombreRegistroInforme"
                      label="Nombre de quien elaboro el Informe"
                      :rules="[required('Nombre de quien elaboro el Informe')]"
                      maxlength="100"
                    ></v-text-field>
                  </v-col>
                </v-row> -->
              </v-form>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.periodo }}</td>
              <td>
                {{ item.situacion }}
              </td>
              <td>{{ item.nombreDirectorAdminFinanciera }}</td>
              <td>{{ item.nombreRegistroInforme }}</td>
              <td>{{ item.fechaRegistro }}</td>
              <td>
                <v-btn
                  v-if="item.estadosId === 1"
                  class="
                    ma-2
                    btn-bg-light  
                    white--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  :href="`http://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ConsolidadoEstimacionAnualCNS/ConsolidadoEstimacionAnualCNS.pdf?endpoint=EstimacionAnualCNS/Consolidado/Documento/${item.id}&j_password=reportes&j_username=reporte`"
                  @click="/*descargarArchivo(item.id, 'pdf')*/"
                  target="_blank"
                  small
                  depressed
                  color="red darken-1"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon> Descargar
                </v-btn>
                <v-btn
                  v-if="item.estadosId === 1"
                  class="
                    ma-2
                    btn-bg-light  
                    white--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  :href="`http://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ConsolidadoEstimacionAnualCNS/ConsolidadoEstimacionAnualCNS.pdf?endpoint=EstimacionAnualCNS/Consolidado/Documento/${item.id}&j_password=reportes&j_username=reporte`"
                  @click="/*descargarArchivo(item.id, 'xlsx')*/"
                  
                  target="_blank"
                  small
                  depressed
                  color="green darken-2"
                >
                  <v-icon left>mdi-file-excel-box</v-icon> Descargar
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!--inicio:: dialog registro -->
    <v-dialog
      v-model="dialogRegistro"
      max-width="800"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ dialogTitle }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--fin:: dialog registro -->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DialogLoader from "@/view/content/DialogLoader";

import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import validations from "@/core/untils/validations.js";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
//import DownloadFile from "@/core/untils/downloadfile.js";
import { 
  GENERAR_CONSOLIDADO_ESTIMACION_ANUAL_CNS,
  OBTENER_CONSOLIDADO_ESTIMACIONES_ENTIDAD
} from "@/core/services/store/proyectoscns/estimacionanualpresupuesto/estimacionanualpresupuesto.module.js";

export default {
  name: "ConsolidadoEstimacionAnualCnsInterno",
  components: {
    DialogLoader,
    SnackAlert,
    DivisorColor
  },
  data() {
    return {
      skeletonLoading: false,
      dialogRegistro: false,
      filtro: "",
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      dialogTitle: "Generar consolidado",
      validFormConsolidado: false,
      btnRegistroLoading: false,
      accionesBloqueadas: false,
      parameters: [],
      entidadId: 0,
      tableLoading: false,
      consolidados: [],
      tipoProyectoItems: [
        { id: 3, text: "Todos" },
        { id: 1, text: "Gestión" },
        { id: 2, text: "Ejecución" }
      ],
      periodos: [],
      ddPeriodosLoading: false,
      datosConsolidado: {},
      anioActual: 0,
      periodoActual: {},
      ...validations
    }
  },
  methods: {
    async obtenerConsolidados() {
      this.tableLoading = true;
      this.consolidados = [];

      await this.$store
        .dispatch(OBTENER_CONSOLIDADO_ESTIMACIONES_ENTIDAD, {
          entidadId: 0
        })
        .then(res => {
          if (res.status === 200) {
            this.consolidados = res.data;
          }
          this.tableLoading = false;
        })
        .catch(() => {
          //console.log(er)
          this.tableLoading = false;
          this.consolidados = [];
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    resetForm() {
      this.dialogTitle = "Generar consolidado ";
      this.accion = 1;
      this.datosConsolidado.estadosId = 1;
      this.datosConsolidado.ENTIDAD_ID = this.entidadId;
      //this.dialogRegistro = true;
      this.datosConsolidado.periodosId = this.periodoActual.id;
    },

    modalNuevo() {
      this.dialogTitle = "Generar consolidado ";
      this.accion = 1;
      this.datosConsolidado.estadosId = 1;
      this.datosConsolidado.ENTIDAD_ID = this.entidadId;
      this.datosConsolidado.periodosId = this.periodoActual.id;
      this.dialogRegistro = true;
    },

    async registrarConsolidado() {
      this.datosConsolidado.usuarioCreacion = "admin";
      this.dialogLoaderText = "Registrando consolidado";
      this.dialogLoaderVisible = true;
      await this.$store
        .dispatch(GENERAR_CONSOLIDADO_ESTIMACION_ANUAL_CNS, {
          datos: this.datosConsolidado
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //this.switchItemEstado = true;
            this.archivoCargado = false;
            this.accion = 2;
            this.dialogRegistro = false;
            this.obtenerConsolidados();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "A ocurrido un error interno, por favor, póngase en contacto con un administrador del sistema " +
              error
          );
          this.btnRegistroLoading = false;
          this.dialogLoaderVisible = false;
        });
    },

    //Obtener tipos de datos financieros
    async obtenerPeriodos() {
      this.periodos = [];

      this.ddPeriodosLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Periodos/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.periodos = res.data;
          }
          this.ddPeriodosLoading = false;
        })
        .catch(() => {
          this.periodos = [];
          this.ddPeriodosLoading = false;
        });
    },

    //!Descargar el archivo de respaldo
    descargarArchivo(id, tipo) {
      let path = `http://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ConsolidadoEstimacionAnualCNS/ConsolidadoEstimacionAnualCNS.${tipo}?endpoint=EstimacionAnualCNS/Consolidado/Documento/${id}&j_password=reportes&j_username=reporte`;
      
      const link = document.createElement('a');
      link.href = path;
      link.target = '_blank';
      link.download = 'my-pdf-file.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      //DownloadFile.downloadExternal(path);
    },

    descargarGeneral(tipo, periodoId){
      let path = `https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ConsolidadoEstimacionAnualGeneralCNS/ConsolidadoEstimacionAnualGeneralCNS.html?endpoint=EstimacionAnualCNS/Consolidado/General/Documento/${tipo}/${periodoId}&j_password=reportes&j_username_0=reporte`;

      console.log(path)
    }
  },
  created() {
    this.anioActual = new Date().getFullYear();
    this.obtenerPeriodos().then(() => {
      this.periodoActual = this.periodos.find(element => {
        if (element.periodo === this.anioActual.toString()) {
          return true;
        }
      });
      this.datosConsolidado.periodosId = this.periodoActual.id;
    });
    this.parameters = this.$route.params;
    this.entidadId = parseInt(this.parameters.entidadId);
   // this.obtenerConsolidados();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Proyectos" },
      { title: "CNS" },
      { title: "Consolidado Estimación Anual Presupuesto" }
    ]);

    // var ciphertext = this.$CryptoJS.AES.encrypt("2", 'KEYADMINCNS2022');
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" }), //, currentRoles :"currentRoles"

    currentRoles: {
      get() {
        return this.$store.state.auth.roles;
      }
    },
    headers() {
      return [
        {
          text: "Año",
          align: "start",
          sortable: true,
          value: "periodo"
        },
        {
          text: "Situación del Proyecto",
          align: "start",
          sortable: true,
          value: "situacion"
        },
        {
          text: "Nombre del Director de adminmistración financiera",
          align: "start",
          sortable: true,
          value: "nombreDirectorAdminFinanciera"
        },
        {
          text: "Nombre de quien registra",
          align: "start",
          sortable: true,
          value: "nombreRegistroInforme"
        },
        {
          text: "Fecha de generación",
          align: "start",
          sortable: true,
          value: "fechaRegistro"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  }
};
</script>
<style lang="">
  
</style>